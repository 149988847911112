import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { Form, InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom'; 
import 'react-loading-skeleton/dist/skeleton.css';

const InquirieList = () => {
    const [inquiries, setInquiries] = useState([]);
    const [loadingInquiries, setLoadingInquiries] = useState(true);
    const [searchKey, setSearchKey] = useState('');

    const navigate = useNavigate(); 
    const inquiryHeaders = ['Name', 'Mobile', 'Email'];

    useEffect(() => {
        getInquiriesList();
    }, []);

    const getInquiriesList = async (search_key = '') => {
        setLoadingInquiries(true);
        try {
            const res = await apicall.post({ search_key }, 'inquiry');
            setInquiries(res.data || []); 
        } catch (error) {
            toast.error(error.message || 'An error occurred while fetching inquiries.');
        } finally {
            setLoadingInquiries(false);
        }
    };

    const handleSearchKeyChange = (e) => {
        setSearchKey(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getInquiriesList(searchKey);
    };

    const handleDetails = (index) => {
        const inquiry = inquiries[index]; 
        navigate(`/inquiey/${inquiry.id}`, { state: { inquiry } }); 
    };

    const formattedInquiries = inquiries.map((inquiry) => [
        inquiry.name,
        inquiry.mobile,
        inquiry.email,
    ]);

    return (
        <div className="container my-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="heading ml-2">Inquiries</h3>
                <div className='mx-5 mb-4 d-flex justify-content-center flex-grow-1'>
                    <Form onSubmit={handleSearchSubmit}>
                        <InputGroup>
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                value={searchKey}
                                onChange={handleSearchKeyChange} 
                                style={{ width: '350px' }}
                            />
                            <InputGroup.Text className='primaryBackground' onClick={handleSearchSubmit}>
                                <FaSearch className='text-white' />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                </div>
            </div>
            {loadingInquiries ? (
                <Skeleton height={30} count={5} style={{ marginBottom: '10px' }} />
            ) : (
                <Table headers={inquiryHeaders} data={formattedInquiries} pagination={true} recordsCount={5} details={handleDetails} />
            )}
        </div>
    );
};

export default InquirieList;