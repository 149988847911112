import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CountryList = () => {

  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);

  const countryHeaders = ['Name', 'Short Name', 'Telephone Code', 'Active'];


  const getCountriesList = async () => {
    try {
      const res = await apicall.get('country');
      setCountries(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred');
    } finally {
      setLoadingCountries(false);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  const countriesData = countries.map(({ name, short_name, telephone_code, is_active }) => [
    name,
    short_name,
    telephone_code,
    is_active ? 'Yes' : 'No',
  ]);

  return (
    <div className="container my-3">

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="heading ml-2">Country</h3>
      </div>
      {loadingCountries ? (
        <div>
          <Skeleton height={30} count={5} style={{ marginBottom: '10px' }} />
        </div>
      ) : (
        <Table headers={countryHeaders} data={countriesData} pagination={true} recordsCount={5} />
      )}
    </div>
  );
};

export default CountryList;