import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as apicall from '../../components/Apicall';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { FaTrash } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';

const AdsForm = () => {
  const { offerings_id } = useParams();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputImageRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteButtonClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteConfirm = async () => {
    console.log('Ad deleted');
    setShowDeleteModal(false);
  };

  const IMAGE_SIZE_LIMIT = 500 * 1024;
  const IMAGE_DIMENSIONS_WIDTH = 720;
  const IMAGE_DIMENSIONS_HEIGHT = 720;

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    shortDescription: Yup.string().required('Short Description is required'),
    description: Yup.string(),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date can’t be before Start Date'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      shortDescription: '',
      description: '',
      image: imagePreview,
      startDate: '',
      endDate: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.description === "" && !imagePreview) {
        toast.error('Please upload an image');
        return;
      }

      setLoading(true);

      try {
        const data = {
          id: offerings_id,
          name: values.name,
          short_description: values.shortDescription,
          description: values.description,
          image: imagePreview,
          start_date: values.startDate,
          end_date: values.endDate,
        };
        var res = await apicall.post(data, '');
        if (res.success) {
          toast.success(res.message);
          handleCancel();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message.replace('Error:', ''));
      } finally {
        setLoading(false);
      }
    },
  });

  const getInputClass = (field) => {
    return formik.touched[field] && formik.errors[field]
      ? 'form-control is-invalid'
      : 'form-control';
  };

  const handleImageUpload = () => {
    fileInputImageRef.current?.click();
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImagePreview(reader.result);
    };
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > IMAGE_SIZE_LIMIT) {
        toast.error('Image file is too large');
        return;
      }
      let newImage = new Image();
      newImage.src = URL.createObjectURL(file);
      newImage.onload = function () {
        let w = newImage.width;
        let h = newImage.height;
        if (w <= IMAGE_DIMENSIONS_WIDTH && h <= IMAGE_DIMENSIONS_HEIGHT) {
          convertToBase64(file);
        } else {
          toast.error('Image dimensions are too large');
          return;
        }
      };
    }
  };

  const handleCancel = () => {
    navigate('/Ads');
  };

  return (
    <div className="container bg-white p-3 mt-4">
      <h3 className="heading">
        <span className="me-4 pointer"><IoArrowBack size={24} onClick={handleCancel} /></span>
        {offerings_id ? 'Edit ' : 'Add a Ad '}
      </h3>

      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3 mt-4">
          <div className="col-md-4">
            <label htmlFor="name" className="label">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className={getInputClass('name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.name}</div>
          </div>
          <div className="col-md-8">
            <label htmlFor="shortDescription" className="label">Description</label>
            <input
              type="text"
              id="shortDescription"
              name="shortDescription"
              className={getInputClass('shortDescription')}
              value={formik.values.shortDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.shortDescription}</div>
          </div>
        </div>

        <div className="mb-3 mt-4">
          <label htmlFor="description" className="label">URL</label>
          <textarea
            id="description"
            name="description"
            className={getInputClass('description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="invalid-feedback">{formik.errors.description}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="startDate" className="label">Start From</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              className={getInputClass('startDate')}
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.startDate}</div>
          </div>
          <div className="col-md-3">
            <label htmlFor="endDate" className="label">End Date</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              className={getInputClass('endDate')}
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.endDate}</div>
          </div>
        </div>
        <div className="form-group mb-4">
          <div className="d-flex align-items-center">
            <div>
              {imagePreview ? (
                <div className="d-flex align-items-center">
                  <img
                    src={imagePreview}
                    alt="Uploaded Image"
                    className="img-fluid me-3 uploaded-image"
                    height={120}
                    width={120}
                  />
                  <button
                    type="button"
                    className="btn primaryColorText btn-outline-secondary ms-2"
                    onClick={() => {
                      setImagePreview(null);
                      if (fileInputImageRef.current) {
                        fileInputImageRef.current.value = '';
                      }
                    }}
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <>
                  <label htmlFor="upload-image" className="content mb-2">Upload an Image:</label>
                  <button
                    type="button"
                    className="btn primaryColorText btn-outline-secondary ms-3 mb-2 mt-2"
                    onClick={handleImageUpload}
                  >
                    Upload
                  </button>
                  <input
                    type="file"
                    id="upload-image"
                    ref={fileInputImageRef}
                    className="d-none"
                    onChange={handleImageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="mt-2">
            <label htmlFor="delete-ad" className="content mb-2">Delete this Ad</label>
            <p className="label">This Ad will be Deleted.</p>
          </div>
          <div>
            <button
              type="button"
              className="btn primaryColorText btn-outline-danger mb-2 mt-2"
              onClick={handleDeleteButtonClick}
            >
              <FaTrash />  Delete
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5 mb-4">
          <button
            type="button"
            className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button type="submit" className="btn Button primaryColorBackground no-hover-effect text-white ms-2">
            Save
          </button>
        </div>
      </form>

      {loading && <Loader />}

      <ToastContainer />

      <Modal show={showDeleteModal} onHide={handleDeleteCancel} centered size='xxl'>
        <Modal.Body>
          <div>
            <p className="text-center mb-3">Are you sure ?you want to <br/>
            delete this Ad?</p>
            <div className="d-flex justify-content-center mt-4">
              <button className="mx-1 btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect" onClick={handleDeleteCancel}>
                Cancel
              </button>
              <button className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center" onClick={handleDeleteConfirm}>
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdsForm;