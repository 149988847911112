import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import Skeleton from 'react-loading-skeleton';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import { Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from '../../components/Loader';

const City = () => {
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ id: '', name: '', stateId: '', countryId: '', isActive: true });

  const headers = ['Name', 'State / Emirate','Country','Is Active'];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    isActive: Yup.boolean().required('Status is required'),
    stateId: Yup.string().required('State is required'),
    countryId: Yup.string().required('Country is required'),
  });

  // Fetch cities data
  const getCitiesList = async () => {
    setLoading(true);
    try {
      const res = await apicall.get('city/0');
      setCities(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred while fetching cities');
    } finally {
      setLoading(false);
    }
  };
  const fetchCountries = async () => {
    try {
      const res = await apicall.get('country');
      setCountries(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred while fetching countries');
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const res = await apicall.get(`state/${countryId}`);
      setStates(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred while fetching states');
    }
  };

  useEffect(() => {
    getCitiesList();
    fetchCountries(); 
  }, []);

  const handleAddCity = () => {
    setModalData({ id: '', name: '', stateId: '', countryId: '', isActive: true });
    setShowModal(true);
  };

  const handleCountryChange = (e, setFieldValue) => {
    const countryId = e.target.value;
    setFieldValue("countryId", countryId);
    setStates([]);
    if (countryId) {
      fetchStates(countryId);
    }
  };

  const handleModalSave = async (values) => {
    setModalLoading(true);
    try {
      const data = {
        state_id: values.stateId,
        country_id: values.countryId,
        name: values.name,
        is_active: 1,
      };
      await apicall.post(data, 'city/0');
      toast.success('City added successfully!');
      setShowModal(false);
      getCitiesList();
    } catch (error) {
      toast.error(error.message || 'An error occurred while saving the city');
    } finally {
      setModalLoading(false);
    }
  };

  const citiesData = cities.map(({ name, state_name,country_name,is_active }) => [
    name,
    state_name,
    country_name,
    is_active ? 'Yes' : 'No',
  ]);

  return (
    <div className="container my-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h3 className="heading ml-2">City</h3>
          <p className="content mt-3">Here is a list of all cities</p>
        </div>
        <button className="btn Button primaryBackground text-white" onClick={handleAddCity}>
          Add City
        </button>
      </div>
      {loading ? (
        <Skeleton count={7} height={40} />
      ) : (
        <Table headers={headers} data={citiesData} pagination={false} recordsCount={6} />
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)} className="mt-5">
        <Modal.Header closeButton>
          <Modal.Title>Add City</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            name: modalData.name,
            stateId: modalData.stateId,
            countryId: modalData.countryId,
            isActive: modalData.isActive,
          }}
          validationSchema={validationSchema}
          onSubmit={handleModalSave}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="countryId" className="form-label">Country</label>
                    <select
                      id="countryId"
                      name="countryId"
                      className={`form-select ${touched.countryId && errors.countryId ? 'is-invalid' : ''}`}
                      value={values.countryId}
                      onChange={(e) => handleCountryChange(e, setFieldValue)}
                      onBlur={handleBlur}
                    >
                      <option value="" label="Select Country" />
                      {countries.map((country) => (
                        <option key={country.id} value={country.id} label={country.name} />
                      ))}
                    </select>
                    {touched.countryId && errors.countryId && (
                      <div className="invalid-feedback">{errors.countryId}</div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="stateId" className="form-label">State</label>
                    <select
                      id="stateId"
                      name="stateId"
                      className={`form-select ${touched.stateId && errors.stateId ? 'is-invalid' : ''}`}
                      value={values.stateId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" label="Select State" />
                      {states.map((state) => (
                        <option key={state.id} value={state.id} label={state.name} />
                      ))}
                    </select>
                    {touched.stateId && errors.stateId && (
                      <div className="invalid-feedback">{errors.stateId}</div>
                    )}
                  </div>

                  <Form.Group className="mt-3">
                    <Form.Label htmlFor="name" className="form-label">Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                 
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center "
                  onClick={handleSubmit}
                >
                  {modalLoading ? <Loader style={{ height: '30px', width: '40px' }} /> : 'Save'}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default City;