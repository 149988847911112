import React, { useState, useEffect } from 'react';
import { FaEye, FaEdit, FaSearch } from 'react-icons/fa';
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as apicall from '../../components/Apicall';
import Skeleton from 'react-loading-skeleton';


const VendorList = () => {

    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [statusList,setStatusList] = useState([{"name":"Active","value":1},{"name":"In Active","value":0}]);
    const [services, setServices] = useState([]);
    const [ratings,setratings] = useState(["0","0.5","1","1.5","2","2.5","3","3.5","4","4.5","5"])
    const [statusFilter, setStatusFilter] = useState({});
    const [ratingFilter, setRatingFilter] = useState("");
    const [serviceTypeFilter, setServiceTypeFilter] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterApplied, setFilterApplied] = useState(false);

    const navigate = useNavigate();


    const getVendorList = async (data) => {
        setLoading(true);
        try {
            const res = await apicall.post(data,"vendor_list");
            setVendors(res.data);
        } catch (e) {
            toast.error(e.message.replace('Error:', ''));
        } finally {
            setLoading(false);
        }
    };

    const getServiceList = async () => {
        try {
            const res = await apicall.get("services");
            setServices(res.data);
        } catch (e) {
            toast.error(e.message.replace('Error:', ''));
        } 
    };

    useEffect(() => {
        getServiceList();
        getVendorList({});
    }, []);

    const handleEditClick = (vendor) => {
        navigate('/vendor/'+vendor.id);
    };

    const handleVendordetails = (vendor) => {
        navigate('/vendorDetails/'+vendor.id);
    };


    const applyFilters = () => {
        var data = {}
        if(statusFilter?.name){
            data.status = statusFilter.value;
             setFilterApplied(true);
        }
        if(ratingFilter != ""){
            data.rating = ratingFilter;
            setFilterApplied(true);
        }
        if(serviceTypeFilter?.id){
            data.service_id = serviceTypeFilter.id
            setFilterApplied(true);
        }
        if(searchTerm != ""){
            data.searchKey = searchTerm;
        }
       
        getVendorList(data);
    };

    const clearAllFilters = () => {
        setStatusFilter({});
        setServiceTypeFilter({});
        setRatingFilter('');
        setFilterApplied(false);
        setShowFilters(false);
        var data = {}
        if(searchTerm != ""){
            data.searchKey = searchTerm;
        }
        getVendorList(data);
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
        if (showFilters) {
            clearAllFilters(); 
        }
    };

    //const isFilterApplied = statusFilter !== '' || serviceTypeFilter !== '' || ratingFilter !== '';
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = vendors.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(vendors.length / rowsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handlePrevious = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
    const handleNext = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    return (
        <div className="vendor-table-container container mt-3">
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className="d-flex align-items-center">
                    <div>
                        <h3>Vendor</h3>
                        <p>Here is a list of all vendors</p>
                    </div>
                </div>

                <div className='mx-5 mb-4'>
                    <InputGroup>
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value.trim().toLowerCase())}
                            style={{ width: '350px' }}
                        />
                        <InputGroup.Text className='primaryBackground' onClick={() => {
                           applyFilters();
                        }}>
                            <FaSearch className='text-white' />
                        </InputGroup.Text>
                    </InputGroup>
                </div>

                <div className="d-flex align-items-center mb-3">
                    <img
                        className='mx-2'
                        src="/image/filter_active_icon.png"
                        alt="Filter Applied"
                        style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
                    />
                    <img
                        className='mx-2'
                        src="/image/filter_iocn.png"
                        alt="Filter"
                        style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
                        onClick={toggleFilters} 
                    />
                    <button className="btn Button primaryBackground text-white" onClick={() => navigate('/vendor/0')}>
                        Add Vendor
                    </button>
                </div>
            </div>

            {showFilters && (
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex gap-3">
                        <DropdownButton
                            id="status-filter"
                            variant="outline-secondary"
                            title={statusFilter.name || 'Status'}
                        >
                            
                            {statusList.map(status => (
                                    <Dropdown.Item key={status.name} onClick={() => setStatusFilter(status)}>
                                        {status.name}
                                    </Dropdown.Item>
                                ))}
                        </DropdownButton>

                        <DropdownButton
                            id="service-filter"
                            variant="outline-secondary"
                            title={serviceTypeFilter.name || 'Service Type'}
                        >
                            {services.length > 0 ? (
                                services.map(service => (
                                    <Dropdown.Item key={service.id} onClick={() => setServiceTypeFilter(service)}>
                                        {service.name}
                                    </Dropdown.Item>
                                ))
                            ) : (
                                <Dropdown.Item disabled>No Service Types Available</Dropdown.Item>
                            )}
                        </DropdownButton>
                        <DropdownButton
                            id="rating-filter"
                            variant="outline-secondary"
                            title={ratingFilter || 'Rating'}
                        >{
                            ratings.map(rating => (
                                <Dropdown.Item key={rating} onClick={() => setRatingFilter(rating)}>
                                    {rating}
                                </Dropdown.Item>
                            ))
                        }
                        </DropdownButton>

                        <button className="btn Button primaryBackground text-white" onClick={applyFilters}>Apply</button>
                        <button className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect" onClick={clearAllFilters}>Clear All</button>
                    </div>
                </div>
            )}

{loading ? (
    <div>
        <Skeleton height={30} count={5} style={{ marginBottom: '10px' }} />
    </div>
) : (
    <>
        {error && <div className="text-center text-danger">{error}</div>}
        <table className="table table-row-spacing table-hover">
            <thead>
                <tr>
                    <th>Sr.No</th>
                    <th>Vendor Name</th>
                    <th>Service Type</th>
                    <th>Contact</th>
                    <th>Membership</th>
                    <th>Rating</th>
                    <th>Edit</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>
                {currentData.length > 0 ? (
                    currentData.map((vendor, index) => (
                        <tr key={vendor.id}>
                            <td>{indexOfFirstRow + index + 1}</td>
                            <td>{vendor.vendor_name}</td>
                            <td>{vendor.service}</td>
                            <td>{vendor.phone_number}</td>
                            <td>{vendor.plan}</td>
                            <td>
                                <span role="img" className='mx-1' aria-label="star">⭐</span>
                                {vendor.vendor_ratings == null ? 0 : vendor.vendor_ratings}
                            </td>
                            <td className="pointer">
                                <FaEdit onClick={() => handleEditClick(vendor)} />
                            </td>
                            <td className="pointer">
                                <FaEye className="mx-3" onClick={() => handleVendordetails(vendor)} />
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="8" className="text-center">No Vendors Found</td>
                    </tr>
                )}
            </tbody>
        </table>

                    {totalPages > 1 && (
                        <nav aria-label="Page navigation example" className="d-flex justify-content-center mt-3">
                            <ul className="pagination">
                                <li className="page-item">
                                    <button
                                        className="btn primaryColorText btn-outline-secondary text-black m-2"
                                        onClick={handlePrevious}
                                        disabled={currentPage === 1}
                                       
                                    >
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                                        <button
                                            className="btn primaryColorText btn-outline-secondary m-2 p-2 btn-sm btn-width"
                                            onClick={() => paginate(pageNumber)}
                                            
                                        >
                                            {pageNumber}
                                        </button>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <button
                                        className="btn  primaryColorText btn-outline-secondary text-black m-2"
                                        onClick={handleNext}
                                        disabled={currentPage === totalPages}
                                      
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    )}
                </>
            )}
        </div>
    );
};

export default VendorList;