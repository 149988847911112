import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaEdit, FaEye } from 'react-icons/fa';

function Table({ headers, data, edit, details, pagination = true, recordsCount }) {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = recordsCount ? Number(recordsCount) : data.length;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = pagination ? data.slice(indexOfFirstRow, indexOfLastRow) : data;

  const totalPages = pagination ? Math.ceil(data.length / rowsPerPage) : 1;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th scope="col">Sr No</th>
        {headers.map((header, index) => (
          <th key={index} scope="col">
            {header}
          </th>
        ))}
        {details && <th scope="col">Details</th>}
        {edit && <th scope="col">Edit</th>}

      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      {currentData.length === 0 ? (
        <tr>
          <td colSpan={headers.length + 2} className="text-center">
            No Records available.
          </td>
        </tr>
      ) : (
        currentData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td>{indexOfFirstRow + rowIndex + 1}</td>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="truncate-cell">
                {cell}
              </td>
            ))}

            {details && (
              <td>
                <FaEye
                  className="greyColor mx-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => details(indexOfFirstRow + rowIndex)}
                />
              </td>
            )}

            {edit && (
              <td>
                <FaEdit
                  className="greyColor mx-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => edit(indexOfFirstRow + rowIndex)}
                />
              </td>
            )}
          </tr>
        ))
      )}
    </tbody>
  );

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-row-spacing table-hover">
          {renderTableHeader()}
          {renderTableBody()}
        </table>
      </div>

      {pagination && totalPages > 1 && (
        <nav aria-label="Page navigation example" className="d-flex justify-content-center mt-3">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="btn primaryColorText btn-outline-secondary text-black m-2"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
              <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                <button
                  className="btn primaryColorText btn-outline-secondary m-2 p-2 btn-sm btn-width "
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="btn primaryColorText btn-outline-secondary text-black m-2  "
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  edit: PropTypes.func,
  details: PropTypes.func,
  pagination: PropTypes.bool,
  recordsCount: PropTypes.number,
};

export default Table;