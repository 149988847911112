import { Routes, Route, Navigate,BrowserRouter,useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './pages/navbar/Navbar';
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import CountryList from "./pages/masters/CountryList";
import StateList from "./pages/masters/StateList";
import City from "./pages/masters/City";
import MemberShipPlans from "./pages/masters/MembershipPlans";
import ServiceList from "./pages/services/ServiceList";
import ServiceOfferingsList from './pages/services/ServiceOfferingsList';
import ServiceOfferingsForm from './pages/services/ServiceOfferingsForm';
import VendorList from "./pages/user/VendorList";
import Staff from "./pages/user/Staff";
import StaffForm from "./pages/user/StaffForm";
import Enduser from "./pages/user/EndUser";
import UserDetails from "./pages/user/UserDetails";
import VendortSignUp from "./pages/user/VendorSignUp";
import Vendordetails from "./pages/user/VendorDetails";
import InquirieList from "./pages/inquiries/InquirieList";
import InquiryDetails from "./pages/inquiries/InquiryDetails";
import CouponsList from "./pages/coupon/CouponsList";
import CouponsForm from "./pages/coupon/CouponsForm";
import CouponDetails from './pages/coupon/CouponDetails';
import AdsList from "./pages/ads/AdsList";
import AdsForm from "./pages/ads/AdsForm";
import NoPage from "./pages/NoPage";



function App() {

  const { pathname } = useLocation();
  const isLoggedIn = localStorage.getItem('token') ? true : false;

  const wrapPrivateRoute = (element) => {
    return (
      <PrivateRoute>
        {element}
      </PrivateRoute>
    );
  };

  return (
    <div>
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      >

      </ToastContainer>
      { (pathname != "/login") && <Navbar /> }
      <Routes>

        <Route path="/" element={ <Navigate to="/login" /> } />

        <Route path="/login" element={<Login />}/>  

        <Route path="/dashboard" element={wrapPrivateRoute(<Dashboard />)}/>  

        <Route path="/services" element={wrapPrivateRoute(<ServiceList />)}/>    

        <Route path="/service_offerings/:service_id" element={wrapPrivateRoute(<ServiceOfferingsList />)}/>

        <Route path="/service_offerings_form/:offerings_id" element={wrapPrivateRoute(<ServiceOfferingsForm />)}/>

        <Route path="/vendors" element={wrapPrivateRoute(<VendorList />)}/>    
        <Route path="/vendor/:vendor_id" element={wrapPrivateRoute(<VendortSignUp />)}/>  
        <Route path="/vendorDetails/:vendor_id" element={wrapPrivateRoute(<Vendordetails />)}/>

        <Route path="/staff" element={wrapPrivateRoute(<Staff />)}/> 
        <Route path="/staffForm/:id" element={wrapPrivateRoute(<StaffForm />)}/>

        <Route path="/user" element={wrapPrivateRoute(<Enduser />)}/>  
        <Route path="/userDetails/:id" element={UserDetails(<Enduser />)}/>

        
        <Route path="/inquiries" element={wrapPrivateRoute(<InquirieList />)}/>  
        <Route path="/inquiey/:id" element={wrapPrivateRoute(<InquiryDetails />)}/>

        <Route path="/coupons" element={wrapPrivateRoute(<CouponsList />)}/>   
        <Route path="/coupon/:id" element={wrapPrivateRoute(<CouponsForm />)}/>   
        <Route path="/couponDetails/:id" element={wrapPrivateRoute(<CouponDetails />)}/>

        <Route path="/ads" element={wrapPrivateRoute(<AdsList />)}/>    
        <Route path="/adsform" element={wrapPrivateRoute(<AdsForm />)}/>
        

        <Route path="/country" element={wrapPrivateRoute(<CountryList />)}/> 
        <Route path="/state" element={wrapPrivateRoute(<StateList />)}/> 
        <Route path="/city" element={wrapPrivateRoute(<City />)}/> 
        <Route path="/memberShip" element={wrapPrivateRoute(<MemberShipPlans />)}/>

        
        

        <Route path="/404" element={wrapPrivateRoute(<NoPage />)}/>
        {/*<Route path="/*" element={ <Navigate to="/404" /> } />*/}

      </Routes>

    </div>
  );
}

export default App;
