import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/Table';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';

const AdsList = () => {
  const [advertisements, setAdvertisements] = useState([]);
  const [filteredAdvertisements, setFilteredAdvertisements] = useState([]);
  const [loadingAdvertisements, setLoadingAdvertisements] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);

  const navigate = useNavigate(); 
  const advertisementHeaders = [
    'Name',
    'Description',
    'Start Date',
    'End Date',
    'Is Active',
    'URL',

  ];

  const statusList = [{ "name": "Active", "value": 1 }, { "name": "In Active", "value": 0 }];

  const getAdvertisementsList = async () => {
    setLoadingAdvertisements(true);
    try {
      const res = await apicall.get('adds'); 
      if (res.success) {
        setAdvertisements(res.data);
        setFilteredAdvertisements(res.data);
      } else {
        toast.error('Failed to fetch advertisements.');
      }
    } catch (e) {
      toast.error(e.message.replace('Error:', ''));
    } finally {
      setLoadingAdvertisements(false);
    }
  };

  useEffect(() => {
    getAdvertisementsList();
  }, []);

  const handleSearch = () => {
    const searchTermLower = searchTerm.trim().toLowerCase();
    const filtered = advertisements.filter(ad =>
      Object.values(ad).some(value => value.toString().toLowerCase().includes(searchTermLower))
    );
    setFilteredAdvertisements(filtered);
  };


  const applyFilters = () => {
    let filtered = advertisements;

    if (statusFilter) {
      filtered = filtered.filter(ad => ad.isActive === statusFilter.value);
    }

    setFilteredAdvertisements(filtered);
    setFilterApplied(true);
  };

  const clearAllFilters = () => {
    setStatusFilter(null);
    setFilteredAdvertisements(advertisements);
    setSearchTerm('');
    setFilterApplied(false);
    setShowFilters(false); 
  };

  const toggleFilters = () => setShowFilters(prev => !prev);


  const advertisementsData = filteredAdvertisements.map(ad => [
    
    ad.name,
    ad.description,
    ad.startDate,
    ad.endDate,
    ad.url,
    ad.isActive ? 'Active' : 'In Active',
  ]);

  const handleEdit =()=>{
    alert('hi')
    
  }

  return (
    <div className="vendor-table-container container mt-3">
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h3>Ads</h3>
          <p>Here is a list of all Ads </p>
        </div>

        <div className='mx-5 mb-4'>
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Search"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: '350px' }}
            />
            <InputGroup.Text className='primaryBackground' onClick={handleSearch}>
              <FaSearch className='text-white' />
            </InputGroup.Text>
          </InputGroup>
        </div>

        <div className="d-flex align-items-center mb-3">
          <img
            className='mx-2'
            src="/image/filter_active_icon.png"
            alt="Filter Applied"
            style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
          />
          <img
            className='mx-2'
            src="/image/filter_iocn.png"
            alt="Filter"
            style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
            onClick={toggleFilters} 
          />
          <button className="btn Button primaryBackground text-white" onClick={() => navigate('/adsform')}>
            Add Ads
          </button>
        </div>
      </div>

      {showFilters && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex gap-3">
            <DropdownButton id="status-filter" variant="outline-secondary" title={statusFilter?.name || 'Status'}>
              {statusList.map(status => (
                <Dropdown.Item key={status.name} onClick={() => setStatusFilter(status)}>
                  {status.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>

            <button className="btn Button primaryBackground text-white" onClick={applyFilters}>Apply</button>
            <button className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect" onClick={clearAllFilters}>Clear All</button>
          </div>
        </div>
      )}

      {loadingAdvertisements ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Table headers={advertisementHeaders} data={advertisementsData} pagination={false} recordsCount={0} edit={handleEdit} />
      )}
    </div>
  );
};

export default AdsList;