import React, { useState, useEffect } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { MdBlock } from 'react-icons/md'; 
import { useNavigate, useLocation } from 'react-router-dom'; 
import { Button, Modal } from 'react-bootstrap';
import VendorServiceHistory from './VendorServiceHistory';
import Skeleton from '../../components/SekeltonLoder';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';

const UserDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation(); 
  const user = state?.user || {}; 
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); 
      setLoading(false);
    };

    fetchData();
  }, []); 

  const handleBlockClick = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const blockUser = async () => {
    try {
      await apicall.get(`/blockUser/${user.id}`); 
      toast.success('User blocked successfully!');
      handleClose(); 
      navigate('/user'); 
    } catch (error) {
      toast.error(error.message || 'An error occurred while blocking the user.');
    }
  };

  const getActiveStatus = (status) => {
    return status === 1 ? 'Yes' : 'No';
  };

  return (
    <div className="container my-4 p-3 shadow rounded-3 bg-white">
      <div className="mb-2 d-flex align-items-center">
        <IoArrowBack size={24} className="me-2 pointer" onClick={() => navigate('/user')} />
        <h1 className="sub-heading mb-0">User Details</h1>
      </div>

      <div className="row Secondary-Variant">
        <h1 className="sub-heading mt-3">Basic Details</h1>
        <div className="col-12 p-3">
          <div className="row">
            <div className="col-4 mb-2">
              <p className="label mb-1">First Name</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.first_name || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Middle Name</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.middle_name || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Country</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.country_name || 'N/A'}</p>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-4 mb-2">
              <p className="label mb-1">State</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.state_name || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">City</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.city_name || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Phone Number</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.phone_number || 'N/A'}</p>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-4 mb-2">
              <p className="label mb-1">Email</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : user.email || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Gender</p>
              {loading ? (
                <Skeleton height="20px" />
              ) : (
                <p className="content mb-1">{user.gender || 'N/A'}</p>
              )}
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Is Active</p>
              <p className="content mb-1">{loading ? <Skeleton height="20px" /> : getActiveStatus(user.is_active) || 'N/A'}</p>
            </div>
          </div>

          {user.is_active === 1 && (
            <div className="row">
              <div className="col-12 mt-3 text-start">
                <p className="content mb-1">Block this user</p>
                <p className="label">This profile will be blocked temporarily.</p>
                <Button variant="btn primaryColorText btn-outline-secondary" onClick={handleBlockClick}>
                  <MdBlock size={20} /> Block
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} centered size="xxl">
        <div className="d-flex flex-column justify-content-center align-items-center p-3 m-3">
          <p className="text-center mb-3">
            Are you sure? You want to block this user?
          </p>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button
              className="mx-1 btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
              onClick={blockUser}
            >
              Block
            </button>
          </div>
        </div>
      </Modal>

      <div className="row mb-3 mt-3">
        <VendorServiceHistory />
      </div>
    </div>
  );
};

export default UserDetails;