import { Button } from "react-bootstrap";
import { useNavigate,NavLink } from "react-router-dom";


const Navbar = () =>{

  const navigate = useNavigate();
  
  const logout = async () => {
    try{ 
        localStorage.clear();
        navigate('/login');
      }catch(err){
        throw new Error(err);
      }
  }

    return (
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <a class="" href="#">
          <img src="/image/logo.png" alt="Logo" width="150px"/> </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

              <NavLink to="services" className= {({ isActive }) => (isActive ? 'nav-link active' : 'nav-link' )}>Services </NavLink>

              <li class="nav-item dropdown">
                <div class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Users </div>
                <ul class="dropdown-menu">
                  <NavLink to="staff">
                    <li class="dropdown-item">Staff</li>
                  </NavLink>
                  <NavLink to="user">
                    <li class="dropdown-item">End User</li>
                  </NavLink>
                  <NavLink to="vendors">
                    <li class="dropdown-item">Vendor</li>
                  </NavLink>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <div class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Master Data </div>
                <ul class="dropdown-menu">

                  <NavLink to="country" className= {({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item' )}>Country</NavLink>
                  <NavLink to="state" className= {({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item' )}>State</NavLink>
                  <NavLink to="city" className= {({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item' )}>City</NavLink>
                  <NavLink to="memberShip" className= {({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item' )}>Membership Plans</NavLink>

                </ul>
              </li>

              <NavLink to="inquiries" className= {({ isActive }) => (isActive ? 'nav-link active' : 'nav-link' )}>Inquiries</NavLink>

              <NavLink to="coupons" className= {({ isActive }) => (isActive ? 'nav-link active' : 'nav-link' )}>Coupons</NavLink>

              <NavLink to="ads" className= {({ isActive }) => (isActive ? 'nav-link active' : 'nav-link' )}>Ads</NavLink>

              <NavLink to="dashboard" className= {({ isActive }) => (isActive ? 'nav-link active' : 'nav-link' )}>Orders</NavLink>

              <NavLink to="dashboard" className= {({ isActive }) => (isActive ? 'nav-link active' : 'nav-link' )}>Chat</NavLink>
             
            </ul>
            {/* <Link className="btn primaryBackground text-white m-1" aria-current="page" to="login">Logout</Link> */}
            <Button className="btn primaryBackground text-white m-1 border-0" aria-current="page" onClick={logout}>Logout</Button>
          </div>
        </div>
      </nav>
    )
}

export default Navbar;