import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as apicall from '../../components/Apicall';

const VendorForm1 = ({ navigate,vendorData, onSubmit }) => {

    const [services, setServices] = useState([]);
    const [memberShipPlans, setMemberShipPlans] = useState([]);
    const [serviceOfferings, setServiceOfferings] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loadingServices, setLoadingServices] = useState(true);
    const [loadingStates, setLoadingStates] = useState(true);
    const [loadingCities, setLoadingCities] = useState(true);
    const [selectedServices, setSelectedServices] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const res = await apicall.get("services");
                setServices(res.data);
                var i = res.data.findIndex(x => x.name === vendorData.service_name);
                formik.setFieldValue('serviceType', i != 1 ? res.data[i].id : '');
                if(i != -1){
                    fetchServiceOfferings(res.data[i].id);
                }
            } catch (error) {
                console.error("Failed to fetch services:", error);
            } finally {
                setLoadingServices(false);
            }
        };

        const fetchCountries = async () => {
            try {
                const res = await apicall.get("country");
                setCountries(res.data);
                var i = res.data.findIndex(x => x.name === vendorData.country_name);
                formik.setFieldValue('country', i != 1 ? res.data[i].id : '');
                if (i != -1) {
                    fetchStates(res.data[i].id);
                }
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        };

        const fetchMemberShipPlan = async () => {
            try {
                const res = await apicall.get("membershipPlans");
                setMemberShipPlans(res.data);

                var i = res.data.findIndex(x => x.name == vendorData.plan_name);
                formik.setFieldValue('membershipType', i != 1 ? res.data[i].id : '');
                
            } catch (error) {
                console.error("Failed to fetch services:", error);
            }
        };

        fetchServices();
        fetchCountries();
        fetchMemberShipPlan();
    }, []);

    useEffect(() => {

    }, [selectedServices]);

    const fetchServiceOfferings = async (serviceId) => {
        setLoadingStates(true);
        var temp = []
        try {
            const res = await apicall.get(`service_offerings/${serviceId}`);
            for (const file of res.data) {
                file.is_selected = false;
                if(vendorData?.serviceOffering){
                    var i = vendorData.serviceOffering.findIndex(x => x.name === file.name);
                    if(i != -1){
                        file.is_selected = true;
                        temp.push(file);
                    }
                }
            }
            setServiceOfferings(res.data);
            formik.setFieldValue('serviceOffering', temp)
            setSelectedServices(temp);
        } catch (error) {
            console.error("Failed to fetch states:", error);
        } finally {
            setLoadingStates(false);
        }
    };
    const offeringsListUpdate = async (offering) => {
        var tempArray = selectedServices;
        offering.is_selected = !offering.is_selected;
        var i = tempArray.findIndex(x => x.name === offering.name);
        if(i != -1){
            tempArray.splice(i, 1);
        }else{
            tempArray.push(offering);
        }
        setSelectedServices(tempArray);
        formik.setFieldValue('serviceOffering', tempArray)
    }

    const fetchStates = async (countryId) => {
        setLoadingStates(true);
        setStates([]);
        setCities([]);
        formik.setFieldValue('state', '');
        formik.setFieldValue('city', '');

        try {
            const res = await apicall.get(`state/${countryId}`);
            setStates(res.data);
            var i = res.data.findIndex(x => x.name === vendorData.state_name);
            formik.setFieldValue('state', i != 1 ? res.data[i].id : '');
            if (i != -1) {
                fetchCities(res.data[i].id);
            }
        } catch (error) {
            console.error("Failed to fetch states:", error);
        } finally {
            setLoadingStates(false);
        }
    };

    const fetchCities = async (stateId) => {
        setLoadingCities(true);
        setCities([]);
        formik.setFieldValue('city', '');

        try {
            const res = await apicall.get(`city/${stateId}`);
            setCities(res.data);
            var i = res.data.findIndex(x => x.name === vendorData.city_name);
            formik.setFieldValue('city', i != 1 ? res.data[i].id : '');
        } catch (error) {
            console.error("Failed to fetch cities:", error);
        } finally {
            setLoadingCities(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            vendorName: vendorData?.vendorName ? vendorData.vendorName : '',
            serviceType: '',
            contactNumber: vendorData?.contactNumber ? vendorData.contactNumber : '',
            country: '',
            state: '',
            city: '',
            email: vendorData?.email ? vendorData.email : '',
            address: vendorData?.address ? vendorData.address : '',
            membershipType: '',
            membershipStartDate: vendorData?.membershipStartDate ? new Date(vendorData.membershipStartDate).toISOString().split('T')[0] : '',
            membershipEndDate: vendorData?.membershipEndDate ? new Date(vendorData.membershipEndDate).toISOString().split('T')[0] : '',
            bankName: vendorData?.bankName ? vendorData.bankName : '',
            bankAccountNumber: vendorData?.bankAccountNumber ? vendorData.bankAccountNumber : '',
            bankIFSC: vendorData?.bankIFSC ? vendorData.bankIFSC : '',
            nameAsPerBank: vendorData?.nameAsPerBank ? vendorData.nameAsPerBank : '',
            tradersLicenseNumber: vendorData?.tradersLicenseNumber ? vendorData.tradersLicenseNumber : '',
            licenseExpDate: vendorData?.licenseExpDate ? new Date(vendorData.licenseExpDate).toISOString().split('T')[0] : '',
            serviceOffering: [],
        },
        validationSchema: Yup.object({
            vendorName: Yup.string().required('Vendor name is required'),
            serviceType: Yup.string().required('Service type is required'),
            contactNumber: Yup.string()
                .required('Contact number is required')
                .matches(/^[0-9]+$/, 'Contact number must be numeric'),
            country: Yup.string().required('Country is required'),
            state: Yup.string().required('State is required'),
            city: Yup.string().required('City is required'),
            email: Yup.string().email('Invalid email format').required('Email is required'),
            address: Yup.string().required('Address is required'),
            membershipType: Yup.string().required('Membership type is required'),
            membershipStartDate: Yup.date()
                .required('Membership start date is required')
                .nullable()
                .min(new Date(), 'Membership start date cannot be in the past'),
            membershipEndDate: Yup.date()
                .required('Membership end date is required')
                .nullable()
                .min(Yup.ref('membershipStartDate'), 'Membership end date must be after start date'),
            bankName: Yup.string().required('Vendor’s Bank Name is required'),
            bankAccountNumber: Yup.string().required('Vendor’s Bank Account Number is required'),
            bankIFSC: Yup.string().required('Vendor’s Bank IFSC is required'),
            nameAsPerBank: Yup.string().required('Name as per the bank account is required'),
            tradersLicenseNumber: Yup.string().required('Trader’s License Number is required'),
            licenseExpDate: Yup.date()
                .required('License Exp. Date is required')
                .nullable()
                .min(new Date(), 'License Exp. Date cannot be in the past'),
        }),
        onSubmit: (values) => {
           // console.log("values = ", values);
            values.service_name = services[services.findIndex(s => s.id == values.serviceType)].name
            values.country_name = countries[countries.findIndex(c => c.id == values.country)].name
            values.state_name = states[states.findIndex(s => s.id == values.state)].name
            values.city_name = cities[cities.findIndex(c => c.id == values.city)].name
            values.plan_name = memberShipPlans[memberShipPlans.findIndex(m => m.id == values.membershipType)].name
            onSubmit(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className='label'>
            <div className="row mb-3">
                <div className="col">
                    <label htmlFor="vendorName" className="form-label">Vendor Name</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.vendorName && formik.errors.vendorName ? 'is-invalid' : ''}`}
                        id="vendorName"
                        name="vendorName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.vendorName}
                    />
                    {formik.touched.vendorName && formik.errors.vendorName && (
                        <div className="invalid-feedback">{formik.errors.vendorName}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="serviceType" className="form-label">Service Type</label>
                    {loadingServices ? (
                        <select className="form-select" disabled>
                            <option>Loading services...</option>
                        </select>
                    ) : (
                        <select
                            id="serviceType"
                            name="serviceType"
                            className={`form-select ${formik.touched.serviceType && formik.errors.serviceType ? 'is-invalid' : ''}`}
                            onChange={(e) => {
                                formik.handleChange(e);
                                fetchServiceOfferings(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.serviceType}
                        >
                            <option value="">Select Service Type</option>
                            {services.map(service => (
                                <option key={service.id} value={service.id}>{service.name}</option>
                            ))}
                        </select>
                    )}
                    {formik.touched.serviceType && formik.errors.serviceType && (
                        <div className="invalid-feedback">{formik.errors.serviceType}</div>
                    )}
                </div>

                <div className="col-md-4 mt-2">
                    <label htmlFor="contact" className="label">Contact</label>
                    <PhoneInput
                        onlyCountries={['ae', 'in']}
                        country={'ae'}
                        value={formik.values.contactNumber}
                        onChange={(value, country, e) => {
                            formik.setFieldValue('contactNumber', value);
                        }}
                        inputStyle={{ width: '100%' }}
                    />
                    {formik.touched.contactNumber && formik.errors.contactNumber && (
                        <div className="text-danger mt-1">{formik.errors.contactNumber}</div>
                    )}
                </div>

            </div>
            <div className="row mb-3">
                <div className="col">
                    <label htmlFor="country" className="form-label">Country</label>
                    <select
                        id="country"
                        name="country"
                        className={`form-select ${formik.touched.country && formik.errors.country ? 'is-invalid' : ''}`}
                        onChange={(e) => {
                            formik.handleChange(e);
                            fetchStates(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                    >
                        <option value="">Select Country</option>
                        {countries.map(country => (
                            <option key={country.id} value={country.id}>{country.name}</option>
                        ))}
                    </select>
                    {formik.touched.country && formik.errors.country && (
                        <div className="invalid-feedback">{formik.errors.country}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="state" className="form-label">State</label>
                    <select
                        id="state"
                        name="state"
                        className={`form-select ${formik.touched.state && formik.errors.state ? 'is-invalid' : ''}`}
                        onChange={(e) => {
                            formik.handleChange(e);
                            fetchCities(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                    >
                        <option value="">Select State</option>
                        {loadingStates ? (
                            <option>Loading states...</option>
                        ) : (
                            states.map(state => (
                                <option key={state.id} value={state.id}>{state.name}</option>
                            ))
                        )}
                    </select>
                    {formik.touched.state && formik.errors.state && (
                        <div className="invalid-feedback">{formik.errors.state}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="city" className="form-label">City</label>
                    <select
                        id="city"
                        name="city"
                        className={`form-select ${formik.touched.city && formik.errors.city ? 'is-invalid' : ''}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                    >
                        <option value="">Select City</option>
                        {loadingCities ? (
                            <option>Loading cities...</option>
                        ) : (
                            cities.map(city => (
                                <option key={city.id} value={city.id}>{city.name}</option>
                            ))
                        )}
                    </select>
                    {formik.touched.city && formik.errors.city && (
                        <div className="invalid-feedback">{formik.errors.city}</div>
                    )}
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-4 ">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="invalid-feedback">{formik.errors.email}</div>
                    )}
                </div>
                <div className="col-8">
                    <label htmlFor="address" className="form-label">Address</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.address && formik.errors.address ? 'is-invalid' : ''}`}
                        id="address"
                        name="address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address && (
                        <div className="invalid-feedback">{formik.errors.address}</div>
                    )}
                </div>
            </div>
            <div className="row mb-3">

                <div className="col">
                    <label htmlFor="membershipType" className="form-label">Membership Type</label>
                    <select
                        id="membershipType"
                        name="membershipType"
                        className={`form-select ${formik.touched.membershipType && formik.errors.membershipType ? 'is-invalid' : ''}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.membershipType}
                    >
                        <option value="">Select Membership Type</option>
                        {memberShipPlans.map(plan => (
                            <option key={plan.id} value={plan.id}>{plan.name}</option>
                        ))}
                    </select>
                    {formik.touched.membershipType && formik.errors.membershipType && (
                        <div className="invalid-feedback">{formik.errors.membershipType}</div>
                    )}
                </div>


                <div className="col">
                    <label htmlFor="membershipStartDate" className="form-label">Membership Start Date</label>
                    <input
                        type="date"
                        className={`form-control ${formik.touched.membershipStartDate && formik.errors.membershipStartDate ? 'is-invalid' : ''}`}
                        id="membershipStartDate"
                        name="membershipStartDate"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.membershipStartDate}
                    />
                    {formik.touched.membershipStartDate && formik.errors.membershipStartDate && (
                        <div className="invalid-feedback">{formik.errors.membershipStartDate}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="membershipEndDate" className="form-label">Membership End Date</label>
                    <input
                        type="date"
                        className={`form-control ${formik.touched.membershipEndDate && formik.errors.membershipEndDate ? 'is-invalid' : ''}`}
                        id="membershipEndDate"
                        name="membershipEndDate"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.membershipEndDate}
                    />
                    {formik.touched.membershipEndDate && formik.errors.membershipEndDate && (
                        <div className="invalid-feedback">{formik.errors.membershipEndDate}</div>
                    )}
                </div>

            </div>
            <div className="row mb-3 mt-3">
                <div className="col">
                    <label htmlFor="bankName" className="form-label">Vendor’s Bank Name</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.bankName && formik.errors.bankName ? 'is-invalid' : ''}`}
                        id="bankName"
                        name="bankName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bankName}
                    />
                    {formik.touched.bankName && formik.errors.bankName && (
                        <div className="invalid-feedback">{formik.errors.bankName}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="bankAccountNumber" className="form-label">Vendor’s Bank Account Number</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.bankAccountNumber && formik.errors.bankAccountNumber ? 'is-invalid' : ''}`}
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bankAccountNumber}
                    />
                    {formik.touched.bankAccountNumber && formik.errors.bankAccountNumber && (
                        <div className="invalid-feedback">{formik.errors.bankAccountNumber}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="bankIFSC" className="form-label">Vendor’s Bank IFSC</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.bankIFSC && formik.errors.bankIFSC ? 'is-invalid' : ''}`}
                        id="bankIFSC"
                        name="bankIFSC"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bankIFSC}
                    />
                    {formik.touched.bankIFSC && formik.errors.bankIFSC && (
                        <div className="invalid-feedback">{formik.errors.bankIFSC}</div>
                    )}
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col">
                    <label htmlFor="nameAsPerBank" className="form-label">Name as per the Bank Account</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.nameAsPerBank && formik.errors.nameAsPerBank ? 'is-invalid' : ''}`}
                        id="nameAsPerBank"
                        name="nameAsPerBank"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.nameAsPerBank}
                    />
                    {formik.touched.nameAsPerBank && formik.errors.nameAsPerBank && (
                        <div className="invalid-feedback">{formik.errors.nameAsPerBank}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="tradersLicenseNumber" className="form-label">Trader’s License Number</label>
                    <input
                        type="text"
                        className={`form-control ${formik.touched.tradersLicenseNumber && formik.errors.tradersLicenseNumber ? 'is-invalid' : ''}`}
                        id="tradersLicenseNumber"
                        name="tradersLicenseNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.tradersLicenseNumber}
                    />
                    {formik.touched.tradersLicenseNumber && formik.errors.tradersLicenseNumber && (
                        <div className="invalid-feedback">{formik.errors.tradersLicenseNumber}</div>
                    )}
                </div>
                <div className="col">
                    <label htmlFor="licenseExpDate" className="form-label">License Exp. Date</label>
                    <input
                        type="date"
                        className={`form-control ${formik.touched.licenseExpDate && formik.errors.licenseExpDate ? 'is-invalid' : ''}`}
                        id="licenseExpDate"
                        name="licenseExpDate"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.licenseExpDate}
                    />
                    {formik.touched.licenseExpDate && formik.errors.licenseExpDate && (
                        <div className="invalid-feedback">{formik.errors.licenseExpDate}</div>
                    )}
                </div>


                <div className="row mb-3 mt-3">
                    <div className="col-12 mb-4">
                        <label className="label mb-3">{serviceOfferings.length > 0 ? `Service Offering` : ``}</label>
                        <div className="d-flex">
                            {serviceOfferings?.map((service, index) => {
                                //const isSelected = selectedServices.includes(service.id);
                                return (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`btn mx-2 btn-outline-secondary text-dark ${service.is_selected ? 'primaryBackground text-white' : ''}`}
                                        onClick={() => {
                                            offeringsListUpdate(service)
                                        }}
                                    >
                                        {service.name}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>


            </div>

            <div className='d-flex justify-content-center mt-5 mb-4'>

                <button
                    type="button"
                    className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
                    onClick={() => navigate('/vendors')}
                >
                    Cancel
                </button>
                <button type="submit" className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center">Next</button>
            </div>

        </form>
    );
};

export default VendorForm1;