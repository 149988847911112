import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import * as apicall from '../../components/Apicall';

const StaffForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { staff_data } = location.state || {};
  console.log("staff_data = ",staff_data)
  const [loading, setLoading] = useState(false);


  const saveStaff = async (values) => { 
  try {
    var data = {
      id: id,
      first_name:values.name,
      middle_name:values.surname,
      last_name:values.lastName,
      email: values.email,
      mobile: values.contact,
      start_date: values.startDate,
      end_date: values.endDate,
      role:values.role,
      address:values.address,
      gender:values.gender
    }
    console.log("data = ",data)
    setLoading(true);
    const res = await apicall.post(data,"staff");
    setLoading(false);
    toast.success(res.message);
    setTimeout(() => {
      navigate('/staff')
    }, 1500);
    } catch (e) {
        toast.error(e.message.replace('Error:', ''));
    } finally {
        setLoading(false);
    }
};

  const validationSchema = Yup.object({
    name: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    contact: Yup.string().required('Contact is required'),
    gender: Yup.string().required('Gender is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date can’t be before Start Date'),
    role: Yup.string().required('Role is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: staff_data ? staff_data.first_name :'',
      lastName: staff_data ? staff_data.last_name :'',
      surname: staff_data ? staff_data.middle_name :'',
      email: staff_data ? staff_data.email :'',
      contact: staff_data ? staff_data.phone_number :'',
      gender: staff_data?.gender == null ? '' : staff_data.gender,
      address: staff_data ? staff_data.address :'',
      startDate: staff_data ? new Date(staff_data.start_date).toISOString().split('T')[0] :'',
      endDate: staff_data ? new Date(staff_data.end_date).toISOString().split('T')[0] :'',  
      role: staff_data ? staff_data.role :'',
    },
    validationSchema,
    onSubmit: (values) => {
      saveStaff(values);
    },
  });

  const getInputClass = (field) =>
    formik.touched[field] && formik.errors[field]
      ? 'form-control is-invalid'
      : 'form-control';

  const getSelectClass = (field) =>
    formik.touched[field] && formik.errors[field]
      ? 'form-select is-invalid'
      : 'form-select';

  return (
    <div className="container bg-white p-3 mt-4">
      <h1 className="heading mt-2">
        <span className="me-4 pointer">
          <IoArrowBack size={24} onClick={() => navigate('/staff')} />
        </span>
        {id == 0 ? "Add a Staff" : "Edit Staff"}
      </h1>
      <hr />
      <h1 className="heading mb-3">Basic Details</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="name" className="form-label label">First Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className={getInputClass('name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.name}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="surname" className="form-label label">Middle Name</label>
            <input
              type="text"
              id="surname"
              name="surname"
              className="form-control"
              value={formik.values.surname}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="lastName" className="form-label label">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className={getInputClass('lastName')}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.lastName}</div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="email" className="form-label label">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className={getInputClass('email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.email}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="contact" className="form-label label">Contact</label>
            <input
              type="text"
              id="contact"
              name="contact"
              className={getInputClass('contact')}
              value={formik.values.contact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.contact}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="gender" className="form-label label">Gender</label>
            <select
              id="gender"
              name="gender"
              className={getSelectClass('gender')}
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select gender" />
              <option value="1" label="Male" />
              <option value="2" label="Female" />
              <option value="3" label="Other" />
            </select>
            <div className="invalid-feedback">{formik.errors.gender}</div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <label htmlFor="address" className="form-label label">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              className="form-control"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className="form-label label">Start Date</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              className={getInputClass('startDate')}
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.startDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="endDate" className="form-label label">End Date</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              className={getInputClass('endDate')}
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.endDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="role" className="form-label label">Role</label>
            <select
              id="role"
              name="role"
              className={getSelectClass('role')}
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select role" />
              <option value="1" label="Admin" />
              <option value="2" label="Customer Support" />
            </select>
            <div className="invalid-feedback">{formik.errors.role}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-4">

  <button
    type="button"
    className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
    onClick={() => navigate('/staff')} 
  >
    Cancel
  </button>


  <button
    type="submit"  
    className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
  >
    {loading ? <Loader style={{ height: "30", width: "40" }} /> : 'Save'}
  </button>
</div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default StaffForm;