import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MemberShipPlans = () => {
  const [plans, setPlans] = useState([]);
  const [loadingStates, setLoadingStates] = useState(true);

  const plansHeaders = ['Name', 'Leads Per Month','Is_active'];


  const getMembershipPlans = async () => {
    try {
      const res = await apicall.get('membershipPlans'); 
      setPlans(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred');
    } finally {
      setLoadingStates(false);
    }
  };

  useEffect(() => {
    getMembershipPlans();
  }, []);

  const plansData = plans.map(({ name, leads_per_month}) => [
    name,
    leads_per_month,
    'Yes',
  ]);

  return (
    <div className="container my-3">
   
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="heading ml-2">Membership Plans</h3>
      </div>
      {loadingStates ? (
        <div>
          <Skeleton height={30} count={5} style={{ marginBottom: '10px' }} />
        </div>
      ) : (
        <Table headers={plansHeaders} data={plansData} pagination={false} recordsCount={10} />
      )}
    </div>
  );
};

export default MemberShipPlans;